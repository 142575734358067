<template>
  <div>
    <div class="text-black">
      <BCol md="12" class="d-flex gap-10 w-100 p-0 my-[24px]">
        <BDropdown
          id="dropdown-1"
          class="filter"
          no-caret
          variant="primary"
          toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
        >
          <h5>Filter</h5>
          <template #button-content>
            <BImg
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              class="height-[20px]"
            />
          </template>
          <BDropdown
            id="dropdown-2"
            class="w-100"
            toggle-class="text-left"
            variant="none"
            left
            no-caret
            dropright
          >
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Skill role</span>
            </template>
            <BDropdownForm style="width: 200px">
              <BFormCheckbox
                v-model="filterSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="skill.id"
                v-for="(skill, index) in listSkills"
                :key="index"
                @change="getListTalent()"
              >
                {{ skill.name }}
              </BFormCheckbox>
            </BDropdownForm>
          </BDropdown>
          <BDropdown
            id="dropdown-3"
            class="w-100"
            toggle-class="text-left"
            variant="none"
            left
            no-caret
            dropright
          >
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Rating</span>
            </template>
            <BDropdownForm style="width: 200px">
              <BFormCheckbox
                v-model="filterRatingSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="star.value"
                v-for="(star, index) in filterRatingOptions"
                :key="index"
                @change="getListTalent()"
              >
                <div class="d-flex">
                  {{ star.star }}
                  <feather-icon
                    icon="StarIcon"
                    class="ml-50 text-warning filled"
                    v-for="n in star.star"
                    :key="n"
                  />
                </div>
              </BFormCheckbox>
            </BDropdownForm>
          </BDropdown>
          <h5
            class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
            @click="resetFilter()"
          >
            Reset filter
          </h5>
        </BDropdown>
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            @input="handleSearch()"
            class="border-0"
          />
        </div>
        <BButton
          tag="router-link"
          to="/talent/create"
          variant="primary"
          class="ml-auto px-3"
        >
          <strong>Tambah</strong>
        </BButton>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(name)="data">
            <div class="flex items-center">
              <img
                v-if="data.item.photo_profile_url === ''"
                src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                alt="Komerce"
                class="w-[40px] h-[40px] rounded-circle"
              />
              <img
                v-else
                :src="data.item.photo_profile_url"
                onerror="this.onerror=null;this.src='https://storage.googleapis.com/komerce/assets/avatars/image-null.png';"
                alt="Komerce"
                class="w-[40px] h-[40px] rounded-circle object-fit-cover"
                loading="lazy"
              />
              <div class="ml-1">
                {{ data.item.full_name }}
              </div>
            </div>
          </template>
          <template #cell(rating)="data">
            <div class="d-flex items-start">
              {{ data.item.rating }}
              <feather-icon icon="StarIcon" class="ml-50 text-warning filled" />
            </div>
          </template>
          <template #cell(show_talent)="data">
            <BFormCheckbox
                :checked="data.item.is_visible > 0 ? true : false"
                switch
                @change="onUpdate(data.item.is_visible, data.item.id)"
              />
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id },
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                />
              </BButton>
              <BButton
                class="btn-icon"
                size="sm"
                variant="flat-danger"
                :to="{
                  name: 'talent-resign',
                  params: { id: data.item.id },
                }"
                tag="router-link"
              >
                <span class="k-trash h-100 text-12 align-middle"></span>
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalDelete :id-item="idItem" @deleted="getListTalent()" />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableNonJob, filterRatingOptions, filterNonjobOptions } from '../config'
import ModalDelete from '../ModalDelete.vue'

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableNonJob,
      idItem: 0,
      filterSelected: [],
      filterRatingSelected: [],
      filterNonjobSelected: [],
      listSkills: [],
      alertError,
      alertSuccess,
      filterRatingOptions,
      filterNonjobOptions,
    }
  },
  mounted() {
    this.getListTalent()
    this.getListSkills()
  },
  computed: {
    skill() {
      return this.filterSelected.join(',')
    },
    rating() {
      return this.filterRatingSelected.join(',')
    },
    duration() {
      return this.filterNonjobSelected.join(',')
    },
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListTalent() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&status=non job&skill=${this.skill}&rating=${this.rating}&non_job_duration=${this.duration}&keyword=${this.keyword}&mode=display`
      const url = `v1/talents?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.items = data
          this.offset = data.length
          this.loading = false

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=non job&skill=${this.skill}&rating=${this.rating}&non_job_duration=${this.duration}&keyword=${this.keyword}&mode=display`
        const url = `v1/talents?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListTalent()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    resetFilter() {
      this.filterSelected = []
      this.filterRatingSelected = []
      this.filterNonjobSelected = []
      this.getListTalent()
    },
    async onUpdate(visible, id) {
      this.loading = true
      const payload = {
        is_visible: visible > 0 ? 0 : 1,
      }

      const url = `v1/talents/${id}/visible_action`
      await komtimAxiosIns
        .post(url, payload)
        .then(() => {
          this.alertSuccess('Berhasil mengubah data')
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '../style/Registered.scss';

.selected {
  background: #dcf3eb;
  border-radius: 4px;
  color: #34a770;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 10px;
  width: 100%;
}
</style>
